import { useState, useCallback, useMemo, useEffect } from 'react'

import { CopyAll, DownloadOutlined, SvgIconComponent } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import classnames from 'classnames'

import { NominatedTow } from '../../Domain/Nomination'
import { groupByKeys, GroupByType } from '../../ui/Table/TableColumnConfig'
import { handleCSVDownload } from '../../utils/downloadFile'
import { getDataByColumns, NominatedBargesTable, Theme } from '../Table/NominatedBargesTable'

import GroupBySelector from './GroupBySelector'
import styles from './Nomination.module.scss'

const theme: Theme = {
  columns: {
    isAvailable: styles.isAvailableCell,
    tboInfo: styles.tboCell,
    inOtherNomination: styles.inOtherNominationCell,
    name: styles.nameCell,
    isAtRisk: styles.isAtRiskCell,
    cargo: styles.cell,
    destination: styles.cell,
    hullType: styles.hullTypeCell,
    tripStatus: styles.cell,
    pickUp: styles.locationCell,
    dropOff: styles.locationCell,
    currentLocation: styles.locationCell,
    pickupType: styles.cell,
    type: styles.cell,
    fleet: styles.cell,
  },
}

type Props = {
  barges: NominatedTow['barges']
  hasReviews: boolean
  bargesInTow?: string[]
}

function Header({
  barges,
  onGroupByChange,
  groupBy,
}: {
  barges: NominatedTow['barges']
  onGroupByChange: (value: GroupByType) => void
  groupBy: GroupByType
}) {
  const bargeNamesCallback = useCallback(() => barges.map(({ name }) => name).join(', '), [barges])

  return (
    <header className={styles.header}>
      <GroupBySelector groupBy={groupBy} groupByKeys={groupByKeys} handleGroupByChange={onGroupByChange} />
      <TowActionButton
        label="Export data to CSV"
        onClick={() => handleCSVDownload({ data: getDataByColumns(barges) })}
        Icon={DownloadOutlined}
      />
      <CopyNames getNames={bargeNamesCallback} />
    </header>
  )
}

export const Nomination = ({ barges, hasReviews }: Props) => {
  const [groupBy, setGroupBy] = useState<GroupByType>(GroupByType.DropOffFacility)

  return (
    <div className={classnames(styles.nomination, { [styles.hasReviews]: hasReviews })}>
      <Header barges={barges} onGroupByChange={setGroupBy} groupBy={groupBy} />
      <NominatedBargesTable hasReviews={hasReviews} barges={barges} theme={theme} groupBy={groupBy} />
    </div>
  )
}

export function CopyNames({ getNames }: { getNames: () => string }) {
  const [open, setOpen] = useState(false)
  const [copied, setCopied] = useState(false)

  const onOpen = useCallback(() => setOpen(true), [])
  const onClose = useCallback(() => {
    if (!copied) {
      setOpen(false)
    }
  }, [copied])

  const title = useMemo(() => (copied ? 'Copied barge names ✔' : 'Copy barge names  from list'), [copied])

  useEffect(() => {
    if (!copied) {
      return undefined
    }

    const timeout = setTimeout(() => setCopied(false), 1000)

    return () => clearTimeout(timeout)
  }, [copied])

  return (
    <Tooltip open={open} onOpen={onOpen} onClose={onClose} title={title} placement="top">
      <button
        type="button"
        className={styles.towActionButton}
        onClick={() => {
          navigator.clipboard.writeText(getNames())
          setCopied(true)
        }}>
        <CopyAll />
      </button>
    </Tooltip>
  )
}

type TowActionButtonProps = {
  label: string
  className?: string | undefined
  Icon: SvgIconComponent
  onClick: () => void
}
const TowActionButton = ({ label, onClick, Icon, className }: TowActionButtonProps) => (
  <Tooltip title={label} placement="top">
    <button className={classnames(styles.towActionButton, className)} type="button" onClick={onClick}>
      <span className="sr-only">{label}</span>
      <Icon />
    </button>
  </Tooltip>
)
